
import mixins from "vue-typed-mixins";
//@ts-ignore
import QRCode from "qrcode";
import _ from "lodash";
import { Hotel } from "@/jpasta-sdk";
export default mixins().extend({
  props: {
    showModal: {
      type: Boolean as () => boolean,
    },
    hotel: {
      type: Object as () => Hotel,
    },
  },
  data: function (): {
    mealType: string;
    showPrintModal: boolean;
  } {
    return {
      mealType: "all",
      showPrintModal: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.mountQrCode();
    });
  },

  methods: {
    getQrCodeUrl: function (): string {
      if (this.mealType === "all")
        return `${process.env.VUE_APP_VIEW_MENU_BASE_URL}/view?hotelid=${this.hotel.id}`;
      else
        return `${process.env.VUE_APP_VIEW_MENU_BASE_URL}/mod?hotelid=${this.hotel.id}&mealindex=${this.mealType}`;
    },
    loadQrCode: function (el) {
      const url = this.getQrCodeUrl();

      return new Promise<void>((resolve, reject) => {
        QRCode.toCanvas(el, url, function (error) {
          if (error) return reject();
          resolve();
        });
      });
    },
    onClosePrintModal: function () {
      this.showPrintModal = false;
    },
    printQrCode: function () {
      window.print();
    },
    onClose: function () {
      this.$emit("close");
    },
    mountQrCode: async function () {
      this.$nextTick(async () => {
        const batch: Promise<any>[] = [];

        _.forEach(this.$refs.qrcodePrint, (canvas) => {
          batch.push(this.loadQrCode(canvas));
        });
        await Promise.all(batch);
      });
      // const canvas = this.$refs.qrcode;

      // if (!canvas) return;

      // await this.loadQrCode(canvas);
    },
  },
  watch: {
    mealType: function (newVal) {
      this.$nextTick(() => {
        if (newVal) this.mountQrCode();
      });
    },
    showModal: function (newVal) {
      this.$nextTick(() => {
        if (newVal) this.mountQrCode();
      });
    },
  },
});
